import React,{Fragment} from 'react';
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import PageHeader from '../components/PageHeader';
import SectionHeader from '../components/SectionHeader';
import ContactUsForm from '../components/ContactUsForm';
import ContentCard from '../components/ContentCard';
import Testimonials from '../components/Testimonials'


export const IndexPageTemplate = ({
  image,
  mainpitch,
  what_we_do,
  what_we_do: { services },
  testimonials,
  contact,
}) => (
  <Fragment>
    <PageHeader heading={mainpitch.title} description={mainpitch.description} image={image} />
    <SectionHeader heading={what_we_do.heading} description={what_we_do.description} />

    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-12">
            <div className="columns is-multiline">
              {services.map(item =>
                <ContentCard
                  key={item.title}
                  image={item}
                  link={item.link}
                  heading={item.title}
                  description={item.text}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section section--gradient">
      <div className="container">
        <div style={{width:'100%', display: 'flex', justifyContent:'center'}}>
          <SectionHeader heading='Customer Successes' />
        </div>
        <Testimonials testimonials={testimonials} />
      </div>
    </section>
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-12 contact-us">
            <h3 className="is-size-2 section-title">
              {contact.title}
            </h3>
            <p>{contact.description}</p>
            <ContactUsForm />
          </div>
        </div>
      </div>
    </section>
  </Fragment>
)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  what_we_do: PropTypes.object,
  mainpitch: PropTypes.object,
  testimonials: PropTypes.object,
  services: PropTypes.array,
  contact: PropTypes.object,
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        what_we_do={frontmatter.what_we_do}
        mainpitch={frontmatter.mainpitch}
        services={frontmatter.services}
        testimonials={frontmatter.testimonials}
        contact={frontmatter.contact}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mainpitch {
          title
          description
        }
        what_we_do {
          heading
          description
          services {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            title
            text
            link
          }
        }
        testimonials {
          name
          title
          company
          image {
            childImageSharp {
              fluid(maxWidth: 240, quality: 64) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          url
          quote
        }
        contact {
          title
          description
        }
      }
    }
  }
`
