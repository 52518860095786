import React from 'react'
import PropTypes from 'prop-types'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import { v4 } from 'uuid'

const Testimonials = ({ testimonials }) => (
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <div className='testimonials'>
        {testimonials.map((testimonial, index) => {
          const flipped = index % 2 === 1
          return (
            <div key={v4()} style={{ marginTop: index === 0 ? '0px': '85px'}} className={flipped ? 'flipped testimonial':'testimonial'}>
                <div className='testimonialImages'>
                  <PreviewCompatibleImage imageStyle={{ width:'120px', borderRadius: 100, margin: '10px' }} imageInfo={testimonial}/>
                </div>
              <div className='testimonialText'>
                <div style={{display: 'flex', flex: 1, flexDirection:'column', justifyContent: 'center'}}>
                  <div className={testimonial.quote.length < 80 ? 'quote big' : 'quote'}>{testimonial.quote}</div>
                </div>
                <div>
                  <div className='name'>{testimonial.name}</div>
                  <div className='jobTitle'>{testimonial.title}</div>
                  <div className='company'><a href={testimonial.url}>{testimonial.company}</a></div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
)

Testimonials.propTypes = {
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      quote: PropTypes.string,
      name: PropTypes.string,
    })
  ),
}

export default Testimonials
